import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import handleBuy from "./useCart";

const Card = ({ ite, priceQuantityData }) => {
  const [selectedPrice, setSelectedPrice] = useState(
    ite.tags[0]?.price || null
  );
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const language = useSelector((state) => state.language.language);

  const initialQuantity =
    priceQuantityData.find((it) => it.price === selectedPrice)?.quantity || 0;
  // const [quantity, setQuantity] = useState(initialQuantity);
  const [orderPlaced, setOrderPlaced] = useState(initialQuantity > 0);
  const [wt, setWt] = useState(0);

  useEffect(() => {
    if (ite.tags.length > 0) {
      setSelectedPrice(ite.tags[0].price);
    }
  }, [ite.tags]);

  useEffect(() => {
    const selectedTag = ite.tags.find((tag) => tag.price === selectedPrice);
    if (selectedTag) {
      setWt(selectedTag.weight);
    }
  }, [selectedPrice, ite.tags]);

  const weight = () => {
    if (wt < 1000) {
      return `${wt} gm`;
    } else {
      return `${(wt / 1000).toFixed(2)} kg`;
    }
  };

  const handleBuyP = (val) => {
    setSelectedPrice(val);
    const item = priceQuantityData.find(
      (it) => it.price === val && it.quantity > 0
    );
    setOrderPlaced(!!item);
  };

  const handleBuyClick = () => {
    if (!orderPlaced) {
      handleBuy(dispatch, ite, selectedPrice, wt, cartItems, setOrderPlaced, 1);
    }
  };

  return (
    <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] max-w-[190px] p-2 h-auto rounded-2xl  md:w-[200px] w-[47vw] bg-white overflow-hidden">
      <div className="">
        <img
          src={ite.image}
          rel="preload"
          type="image/webp"
          loading="lazy"
          alt={ite.title}
          className="w-full h-40 object-cover rounded-xl"
        />
      </div>
      <div className="">
        <h2 className="font-bold text-lg">
          {language === 0 ? ite.title : ite.hTitle}
        </h2>
        <div className="font-semibold">{weight()}</div>

        <div className="flex-row text-xs flex-wrap items-center md:gap-3 gap-1">
          <div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-3 text-sm h-auto items-center justify-center mr-4 mt-2">
            {ite.tags.map((tag, index) => {
              return (
                <div
                  key={tag._id}
                  className={`p-1 px-0 border-2 border-amber-500 w-auto text-center rounded-lg  cursor-pointer font-bold ${
                    selectedPrice === tag.price
                      ? "bg-amber-500 text-white"
                      : "bg-white text-amber-500 hover:bg-amber-600 hover:text-white"
                  }`}
                  onClick={() => handleBuyP(tag.price)}
                >
                  ₹ {tag.price}
                </div>
              );
            })}
          </div>

          {orderPlaced ? (
            <button className="w-full mt-4 text-sm py-2 bg-white border-2 border-green-500 rounded-[0.72rem] font-semibold text-green-500">
              Added to cart
            </button>
          ) : (
            <button
              onClick={handleBuyClick}
              className="w-full mt-4 text-sm py-2 bg-green-500 rounded-[0.72rem] font-semibold text-white"
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addToCart } from "../Redux/cartActions";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

// const Card = ({ ite, priceQuantityData }) => {
//   const [selectedPrice, setSelectedPrice] = useState(
//     ite.tags[0]?.price || null
//   );
//   const dispatch = useDispatch();
//   const initialQuantity =
//     priceQuantityData.find((it) => it.price === selectedPrice)?.quantity || 0;
//   const language = useSelector((state) => state.language.language);

//   const [quantity, setQuantity] = useState(initialQuantity);
//   const [orderPlaced, setOrderPlaced] = useState(quantity > 0);
//   const handleBuyP = (val) => {
//     setSelectedPrice(val);
//     const item = priceQuantityData.find(
//       (it) => it.price === val && it.quantity > 0
//     );
//     setOrderPlaced(!!item);
//   };
//   const [wt, setWt] = useState(0);

//   useEffect(() => {
//     if (ite.tags.length > 0) {
//       setSelectedPrice(ite.tags[0].price);
//     }
//   }, [ite.tags]);

//   useEffect(() => {
//     const selectedTag = ite.tags.find((tag) => tag.price === selectedPrice);
//     if (selectedTag) {
//       setWt(selectedTag.weight);
//     }
//   }, [selectedPrice, ite.tags]);

//   const weight = () => {
//     if (wt < 1000) {
//       return `${wt} gm`;
//     } else {
//       return `${(wt / 1000).toFixed(2)} kg`;
//     }
//   };

//   const handleBuy = () => {
//     if (!orderPlaced) {
//       setQuantity(1);

//       dispatch(
//         addToCart({ ...ite, quantity: 1, price: selectedPrice, weight: wt })
//       );
//       setOrderPlaced(true);
//     }
//   };

//   // const handleIncrease = () => {
//   //   const newQuantity = quantity + 1;
//   //   setQuantity(newQuantity);
//   //   dispatch(
//   //     addToCart({ ...ite, quantity: newQuantity, price: selectedPrice })
//   //   );
//   // };

//   // const handleDecrease = () => {
//   //   if (quantity > 1) {
//   //     const newQuantity = quantity - 1;
//   //     setQuantity(newQuantity);
//   //     dispatch(
//   //       addToCart({ ...ite, quantity: newQuantity, price: selectedPrice })
//   //     );
//   //   } else {
//   //     setQuantity(0);
//   //     setOrderPlaced(false);
//   //     handleRemove(ite);
//   //   }
//   // };

//   // const handleRemove = () => {
//   //   dispatch(addToCart({ ...ite, quantity: 0, price: selectedPrice })); // Set quantity to 0 to remove
//   // };
// // bg-gradient-to-t from-amber-300 to-amber-500
//   return (
//     <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] max-w-[190px] p-2 h-auto rounded-2xl  md:w-[200px] w-[47vw] bg-white overflow-hidden">
//       <div className="">
//         <img
//           src={ite.image}
//           alt={ite.title}
//           className="w-full h-40 object-cover rounded-xl"
//         />
//       </div>
//       <div className="">
//         <h2 className="font-bold text-lg">
//           {language === 0 ? ite.title : ite.hTitle}
//         </h2>
//         <div className="font-semibold">{weight()}</div>

//         <div className="flex-row text-xs flex-wrap items-center md:gap-3 gap-1">
//           <div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-3 text-sm h-auto items-center justify-center mr-4 mt-2">
//             {ite.tags.map((tag, index) => {
//               return (
//                 <div
//                   key={tag._id}
//                   className={`p-1 px-0 border-2 border-amber-500 w-auto text-center rounded-lg  cursor-pointer font-bold ${
//                     selectedPrice === tag.price
//                       ? "bg-amber-500 text-white"
//                       : "bg-white text-amber-500 hover:bg-amber-600 hover:text-white"
//                   }`}
//                   onClick={() => handleBuyP(tag.price)}
//                 >
//                   ₹ {tag.price}
//                 </div>
//               );
//             })}
//           </div>

//           {/* {orderPlaced && (
//             <div className="flex items-center mt-4 border-2 gap-4  border-green-500 justify-center rounded-xl">
//               <button
//                 onClick={handleDecrease}
//                 className="mx-2 p-1 text-red-600"
//                 disabled={quantity === 0}
//                 aria-label="Decrease quantity"
//               >
//                 <FontAwesomeIcon icon={faMinusCircle} size="2xl" />
//               </button>
//               <span className="text-xl">{quantity}</span>
//               <button
//                 onClick={handleIncrease}
//                 className="mx-2 p-1 text-green-600"
//                 aria-label="Increase quantity"
//               >
//                 <FontAwesomeIcon icon={faPlusCircle} size="2xl" />
//               </button>
//             </div>
//           )} */}
//           {orderPlaced && (
//             <button
//               className="w-full mt-4 text-sm py-2 bg-white border-2 border-green-500 rounded-[0.92rem]  font-semibold text-green-500"
//               aria-label="Add to cart"
//             >
//               Added to cart
//             </button>
//           )}

//           {!orderPlaced && (
//             <button
//               onClick={handleBuy}
//               className="w-full mt-4 text-sm py-2 bg-green-500 rounded-[0.92rem] font-semibold text-white"
//               aria-label="Add to cart"
//             >
//               Add to Cart
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;
