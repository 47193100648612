import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useSelector, useDispatch } from "react-redux";
import { loginWithGoogle, setUser } from "../Redux/userActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input";
const LoginPage = ({ handleClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const [insertOtp, setInsertOtp] = useState(false);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (user) {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [user, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setInsertOtp(false);
    setOtp("");
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    handleClick("User", "Submitted Login Form", "Login Form", 1);
    setLoading(true); // Set loading to true
    try {
      toast.loading("Please Wait ...");
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/sendotp`,
        { email }
      );

      if (response.data.success) {
        setInsertOtp(true);
        toast.dismiss(); // Dismiss loading toast
        toast.success("OTP sent successfully!"); // Notify user
      } else {
        toast.dismiss(); // Dismiss loading toast
        toast.error(response.data.message || "Failed to send OTP."); // Notify if OTP sending fails
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login failed. Please try again.";
      toast.dismiss(); // Dismiss loading toast
      toast.error(errorMessage); // Notify error
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleVerifyAndSignup = async (e) => {
    e.preventDefault();
    toast.loading("Please Wait ...");
    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/verifyemail`,
        { email, otp }
      );
      const { user, token } = otpResponse.data;
      dispatch(setUser(user, token));
      toast.dismiss(); // Dismiss loading toast
      toast.success("Login successful!"); // Notify success
      setTimeout(() => {
        navigate("/cart");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Verification failed. Please try again.";
      toast.dismiss(); // Dismiss loading toast
      toast.error(errorMessage); // Notify error
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    handleClick("User", "Submitted Login Form", "Login Form", 1);

    try {
      const { credential } = credentialResponse;
      await dispatch(loginWithGoogle(credential));
      toast.success("Login Successful!");
      setTimeout(() => {
        navigate("/cart");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
    } catch (error) {
      toast.error(
        "Google Login Failed: " +
          (error.response?.data?.message || "Unknown error")
      );
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="flex flex-col items-center justify-center bg-gray-100 min-h-[100vh]">
        <h1 className="text-3xl mb-4">Login</h1>
        <div className="w-80">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onFailure={(error) => {
              toast.error("Failed to login with Google");
            }}
            style={{ width: "100%" }}
          />
        </div>
        <div className="my-4">or</div>
        <div className="flex flex-col w-80 bg-white  rounded shadow-md">
          <form
            className="flex flex-col w-80 bg-white p-4 rounded"
            onSubmit={handleEmailLogin}
          >
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className="p-2 mb-2 border border-gray-300 rounded outline-orange-500"
              required
            />
            {!insertOtp && (
              <button
                type="submit"
                className="bg-orange-400 text-white p-2 rounded hover:bg-orange-500 transition"
                disabled={loading}
              >
                {loading ? "Sending OTP..." : "Generate OTP"}
              </button>
            )}
          </form>
          {insertOtp && (
            <div className="flex flex-col w-80 p-4 pt-0 rounded ">
              <p className="text-[1rem] leading-[1.3rem] my-4 ">
                Verification code has been sent to your Gmail. Please check your
                <b> inbox or spam folder.</b>{" "}
              </p>
              <form onSubmit={handleVerifyAndSignup}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  isInputNum
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder=""
                      style={{
                        boxShadow:
                          "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                      }}
                      className="w-[48px] lg:w-[60px] border-2 border-gray-400 bg-richblack-800 rounded-[0.5rem] text-richblack-5 aspect-square text-center focus:border-0 focus:outline-2  focus:outline-orange-500"
                    />
                  )}
                  containerStyle={{
                    justifyContent: "space-between",
                    gap: "0 6px",
                  }}
                />
                <button
                  type="submit"
                  className="w-full bg-orange-400 py-[12px] px-[12px] rounded-[8px] mt-6 font-medium text-white"
                >
                  Verify Email
                </button>
              </form>
              <div className="mt-6 flex items-center justify-between">
                <button
                  className="flex items-center text-blue-500 gap-x-2"
                  onClick={() => {
                    toast.loading("Resending OTP...");
                    axios
                      .post(
                        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/sendotp`,
                        { email }
                      )
                      .then(() => {
                        toast.success("OTP resent successfully!");
                      })
                      .catch(() => {
                        toast.error("Failed to resend OTP.");
                      })
                      .finally(() => {
                        toast.dismiss();
                      });
                  }}
                >
                  Resend it
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
