import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const HeroSection = ({ mainRef }) => {
  const language = useSelector((state) => state.language.language) || 0;
  const [display, setDisplay] = useState("none");

  const head = ["Prasad", "प्रसाद"];
  const quote = [
    "Choose with devotion, offered with faith",
    "श्रद्धा से चुनें, आस्था से अर्पित करें",
  ];

  useEffect(() => {
    const checkDisplay = () => {
      if (window.innerWidth >= 768) {
        setDisplay("block");
      } else {
        setDisplay("none");
      }
    };

    checkDisplay();
    window.addEventListener("resize", checkDisplay);

    return () => {
      window.removeEventListener("resize", checkDisplay);
    };
  }, []);

  const scrollToMain = () => {
    if (mainRef.current) {
      const elementPosition =
        mainRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition =
        elementPosition -
        1.4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="md:mt-10 md:block px-2 md:px-12 md:rounded-lg border-t-2 border-white min-h-[95vh] "
      style={{
        backgroundImage: `url(https://res.cloudinary.com/dtit4w15r/image/upload/v1740685237/Images/qxnhw9zkcidujwsvtf6c.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: display,
      }}
    >
      <div className="md:px-10 md:mt-10 px-2 bg-gray-50">
        <div
          className="block text-4xl md:hidden"
          style={{
            fontFamily: "Montserrat, serif",
            fontOpticalSizing: "auto",
            fontWeight: 600,
            fontStyle: "normal",
          }}
        >
          {head[language]}
          <h1 className="quote-text ml-[0.199rem] text-base italic">
            {quote[language]}
          </h1>
        </div>
      </div>
      <div className="absolute hidden md:block bottom-[6.5rem] left-0 border-orange-500 border-2 w-[42.5%]"></div>
      <button
        onClick={scrollToMain}
        className="hidden md:block outline-none absolute bottom-20 left-[43%] p-3 text-white md:text-lg text-base border-orange-500 bg-orange-500 rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500 border hover:border-orange-600 hover:shadow-lg hover:rounded-full hover:border-2"
      >
        {language === 0 ? "Offer Prasadam" : "प्रसाद अर्पित करें"}
      </button>
    </div>
  );
};

export default HeroSection;
