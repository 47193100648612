import { CheckCircle } from "lucide-react";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

export const checkIconVariants = {
  hidden: { scale: 0, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: { duration: 0.5, ease: "easeOut" },
  },
};

export const contentVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.5, delay: 0.2 },
  },
};

export default function AnimatedVerifiedPage() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="text-center flex flex-col items-center">
        <motion.div
          variants={checkIconVariants}
          initial="hidden"
          animate="visible"
          className="mx-auto"
        >
          <CheckCircle className="w-24 h-24 text-green-500" />
        </motion.div>

        <motion.div
          variants={contentVariants}
          initial="hidden"
          animate={showContent ? "visible" : "hidden"}
          className="mt-4"
        >
          <h1 className="text-2xl font-bold text-gray-800">Code Verified</h1>
          <p className="mt-6 text-xl text-gray-600">Watch video proof</p>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 inline-block px-10 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300 ease-in-out"
          >
            Watch
          </a>
        </motion.div>
      </div>
    </div>
  );
}
