import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function redirectToPayment(url) {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
}
export async function initiatePayment(FormData) {
  const toastId = toast.loading("Loading...");

  try {
    if (!FormData.name || !FormData.contactNo) {
      // show error toast
      toast.error("Please fill all the fields");
      return "nosuccess";
    }
    if(FormData.contactNo.length !== 10){
      toast.error("Please enter a valid mobile number");
      return "nosuccess";}
    const orderResponse = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/payonlinerose`,
      { dataToSubmit: FormData }
    );

    if (!orderResponse.data.success) {
      throw new Error(orderResponse.data.message || "Failed to create order.");
    }

    const paymentUrl = orderResponse.data.redirectUrl;
    const orderId = orderResponse.data.paymentId;

    redirectToPayment(paymentUrl);

    const paymentVerificationResult = await verifyPayment(orderId);

    if (paymentVerificationResult.success) {
      return "success";
    } else {
      throw new Error("Payment verification failed.");
    }
  } catch (error) {
    toast.error(
      "Could not make Payment: " +
        (error.response?.data?.message || error.message)
    );
    return "nosuccess";
  } finally {
    toast.dismiss(toastId);
  }
}

async function verifyPayment(orderId) {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/paymentverifyrose?id=${orderId}`
    );
    return { success: true };
  } catch (error) {
    return { success: false };
  }
}

const RosePayment = () => {
  const [formData, setFormData] = useState({ name: "", contactNo: "" });
  const [focusedInput, setFocusedInput] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFocus = (field) => {
    setFocusedInput(field);
  };

  const handleBlur = () => {
    setFocusedInput("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await initiatePayment(formData);
    if (result === "success") {
      toast.success("Payment successful!");
      navigate("/");
    }
  };
  const language = useSelector((state) => state.language.language);
  return (
    <div className="min-h-[100vh] flex justify-center items-center">
      <div className="max-w-md mx-auto p-4 mt-16 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] rounded-3xl">
        <div className="p-2 py-3 max-w-md h-auto relative rounded-2xl  bg-white flex overflow-hidden">
          <div className="w-2/5">
            <img
              src={
                "https://res.cloudinary.com/dtit4w15r/image/upload/v1740592103/Rosee_glnzqq.png"
              }
              alt="Rose"
              rel="preload"
              loading="lazy"
              type="image/webp"
              className="w-11/12 h-[150px] object-contain rounded-xl"
            />
          </div>
          <div className="absolute  -top-4 bg-gradient-to-b from-amber-300 to-amber-500 -left-3 pl-6 pr-3 pt-4 font-bold rounded-2xl">
            {language === 0 ? "Rose" : "गुलाब"}
          </div>

          <div className="w-3/5 flex flex-col justify-between">
            <div>
              <p
                className={`font-bold text-lg ${
                  language === 1 ? "font-[Noto Sans Devanagari]" : ""
                }`}
              >
                {language === 0
                  ? "Rose for Khatu Shyam Ji"
                  : "खाटू श्याम जी के लिए गुलाब"}
              </p>
              <p
                className={`text-sm text-gray-700 mt-2 ${
                  language === 1 ? "font-[Noto Sans Devanagari]" : ""
                }`}
              >
                {language === 0
                  ? "Offering a rose to Khatu Shyam Ji symbolizes love, and respect, bringing peace and blessings to the devotee."
                  : "खाटू श्याम जी को गुलाब अर्पित करना प्रेम, सम्मान और भक्ति का प्रतीक है, जो भक्त को शांति और आशीर्वाद प्रदान करता है।"}
              </p>
            </div>

            <div>
              <div className="flex w-full items-baseline gap-2">
                <div className="p-1 px-0 text-amber-500 w-auto  text-2xl rounded-lg font-bold">
                  ₹{29}
                </div>
                <div className="line-through text-gray-600 italic">₹{35}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4"></div>
        <form onSubmit={handleSubmit} className="grid gap-4">
          <label className="relative block mb-5">
            <input
              type="text"
              name="name"
              id="name"
              required
              value={formData.name}
              onChange={handleChange}
              onFocus={() => handleFocus("name")}
              onBlur={handleBlur}
              className="w-full border-2 outline-none border-orange-500 bg-transparent text-gray-800 p-3 rounded-md text-base transition duration-300 ease-in-out"
            />
            <span
              className={`absolute top-3 transition-all ease-in-out text-base font-bold text-gray-500 ${
                formData.name || focusedInput === "name"
                  ? "transform -translate-y-6 scale-75 bg-white px-1 left-1"
                  : "transform translate-y-0 scale-100 left-4"
              }`}
            >
              Full Name
            </span>
          </label>

          <label className="relative block mb-5">
            <input
              type="number"
              name="contactNo"
              id="contactNo"
              min="00000000"
              max="9999999999"
              required
              value={formData.contactNo}
              onChange={handleChange}
              onFocus={() => handleFocus("contactNo")}
              onBlur={handleBlur}
              className="w-full border-2 outline-none border-orange-500 bg-transparent text-gray-800 p-3 rounded-md text-base transition duration-300 ease-in-out"
            />
            <span
              className={`absolute top-3 transition-all ease-in-out text-base font-bold text-gray-500 ${
                formData.contactNo || focusedInput === "contactNo"
                  ? "transform -translate-y-6 scale-75 bg-white px-1 left-1"
                  : "transform translate-y-0 scale-100 left-4"
              }`}
            >
              Mobile Number
            </span>
          </label>

          <div className="w-full flex justify-center items-center mt-4">
            <button
              type="submit"
              className="px-5 py-2 md:text-xl text-lg text-white bg-orange-500 rounded-2xl font-bold transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500 hover:border-orange-500 hover:shadow-lg hover:rounded-xl hover:border-2"
            >
              Pay Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RosePayment;
