import React, { useState } from "react";
import html2canvas from "html2canvas";

const shareLetter =
  "https://res.cloudinary.com/dtit4w15r/image/upload/v1740593686/Share_Later_Card_XYZ_mrbsmp.svg?fm=webp";

const DownloadDivAsPNG = ({ name, setName }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleDownload = async () => {
    try {
      const canvas = await html2canvas(document.getElementById("downloadDiv"), {
        useCORS: true,
        scale: 2,
      });

      const imageURL = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.download = "ParamBhog.png";
      link.href = imageURL;
      link.click();
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete("name");
      window.history.replaceState({}, "", currentUrl.toString());
      setName("");
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true); // Remove blur effect once image is loaded
  };

  return (
    <div className="space-y-4 bg-transparent text-center">
      <div
        id="downloadDiv"
        className="relative flex items-center justify-center w-auto h-full"
      >
        {name && (
          <p className="absolute top-[10.5rem] z-10 w-full text-white text-xl font-semibold">
            {name.trim()} Ji
          </p>
        )}

        {/* Image with blur effect initially */}
        <img
          src={shareLetter}
          alt="Share Letter"
          className={`w-auto h-96 rounded-3xl transition-all duration-1000 ${
            !imageLoaded ? "filter blur-xl" : "filter blur-0"
          }`}
          loading="lazy"
          crossOrigin="anonymous"
          onLoad={handleImageLoad} // Trigger when the image has fully loaded
        />
      </div>

      <button
        onClick={handleDownload}
        className="w-full py-2 rounded-md shadow-md bg-blue-500 hover:bg-blue-600 text-white transition"
      >
        Download & Share
      </button>
    </div>
  );
};

export default DownloadDivAsPNG;
