import React, { useState, useEffect, useRef } from "react";
import "../App.css";

const homeImages = [
  {
    link: "https://res.cloudinary.com/dtit4w15r/image/upload/v1740683893/Group_1321314237_t16nwo.svg?fm=webp",
  },
  {
    link: "https://res.cloudinary.com/dtit4w15r/image/upload/v1740685036/Images/ha4p8zn8mwf9hioizgsl.png?fm=webp",
  },
  {
    link: "https://res.cloudinary.com/dtit4w15r/image/upload/v1740683893/Group_1321314235_dse8ic.svg?fm=webp",
  },
];

const HomeImagesSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [imageLoaded, setImageLoaded] = useState([]);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const intervalRef = useRef(null); // Keep track of auto-slide interval
  const sliderRef = useRef(null); // Ref for the slider element

  useEffect(() => {
    startAutoSlide();
    preloadImages();
    preloadLinks();
    const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
      if (touchStartX.current - touchEndX.current > 50) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % homeImages.length);
      }

      if (touchEndX.current - touchStartX.current > 50) {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + homeImages.length) % homeImages.length
        );
      }
      startAutoSlide();
    };

    const slider = sliderRef.current;
    slider.addEventListener("touchstart", handleTouchStart);
    slider.addEventListener("touchmove", handleTouchMove);
    slider.addEventListener("touchend", handleTouchEnd);

    return () => {
      slider.removeEventListener("touchstart", handleTouchStart);
      slider.removeEventListener("touchmove", handleTouchMove);
      slider.removeEventListener("touchend", handleTouchEnd);
    };
  }, [homeImages.length]);

  const startAutoSlide = () => {
    clearInterval(intervalRef.current); 
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === homeImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3500);
  };

  const preloadImages = () => {
    const newImageSrcs = homeImages.map((image) => ({
      lowRes: `${image.link}?w=600&h=400&fm=webp`,
      midRes: `${image.link}?w=1200&h=800&fm=webp`,
      highRes: `${image.link}?w=2000&h=1300&fm=webp`,
    }));
    setImageSrcs(newImageSrcs);
    setImageLoaded(new Array(homeImages.length).fill(false)); 
  };

  const preloadLinks = () => {
    homeImages.forEach((image) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = image.link;
      link.as = "image";
      link.type = "image/webp";
      document.head.appendChild(link);
    });
  };

  // const goToSlide = (index) => {
  //   setCurrentIndex(index);
  //   startAutoSlide(); 
  // };

  const handleImageLoad = (index) => {
    setImageLoaded((prevState) => {
      const updated = [...prevState];
      updated[index] = true; 
      return updated;
    });
  };

  const handleImageError = (index) => {
    // If the image fails to load, you can set a fallback image or show an error image
    setImageLoaded((prevState) => {
      const updated = [...prevState];
      updated[index] = false; // Reset to show low-quality image
      return updated;
    });
  };

  return (
    <div ref={sliderRef} className="relative w-full h-[36vh] mt-16 mx-auto ">
      <div className=" w-full ">
        {homeImages.map((image, index) => (
          <img
            key={index}
            src={imageSrcs[index]?.highRes}
            className={`absolute w-full h-auto max-h-[80vh] object-contain transition-opacity duration-700 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
            alt={`Slide ${index + 1}`}
            onLoad={() => handleImageLoad(index)}
            onError={() => handleImageError(index)}
          />
        ))}
      </div>

      {/* <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {homeImages.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === currentIndex
                ? "bg-orange-500 scale-125"
                : "bg-white bg-opacity-50"
            }`}
          ></button>
        ))}
      </div> */}
    </div>
  );
};

export default HomeImagesSlider;
