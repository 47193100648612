import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage } from "../Redux/languageActions";
import logo from "./Images/logo.svg";
import wb from "./Images/whatsapp.svg";
const Navbar = () => {
  const cartItems = useSelector((state) => state.cart.items || []);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const arr = ["हिन्दी", "English"];
  const [totalItem, setTotalItem] = useState(0);

  const getAll = () => {
    let ans = 0;
    cartItems.forEach((item) => {
      ans += item.quantity;
    });
    setTotalItem(ans);
  };

  useEffect(() => {
    getAll();
  }, [cartItems]);

  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleClickHome = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  // const user = useSelector((state) => state.user.user);

  const handleBuyButton = (e) => {
    e.preventDefault();
    // if (user) {
    navigate("/cart");
    // } else navigate("/login");
  };

  return (
    <div className="font-semibold md:px-5 px-2 shadow-md fixed w-full top-0 z-10 bg-white bg-opacity-40 backdrop-blur-xl">
      <div className="navbar flex justify-between items-center p-2">
        <div className="flex items-center">
          <Link to="/" onClick={handleClickHome}>
            <img
              src={logo}
              alt="Logo"
               loading="lazy"
              className="md:h-8 h-7 md:w-auto max-w-max"
            />
          </Link>
          <Link to="/" onClick={handleClickHome} className="no-underline">
            <h1 className="text-orange-500 ml-3 font-bold text-lg">
              ParamBhog
            </h1>
          </Link>

          <div className="ml-8 sm:mr-14 md:ml-28 flex-col cursor-pointer  text-lg">
            <p
              id="lang"
              className="font-semibold hover:text-orange-500"
              onClick={() => dispatch(toggleLanguage())}
            >
              {arr[language]}
            </p>
          </div>
        </div>

        <div className="hidden text-lg md:flex space-x-7 font-semibold">
          <Link
            to="/"
            onClick={handleClickHome}
            className="c hover:text-orange-500"
          >
            Home
          </Link>
          <Link
            to="/"
            onClick={handleClick}
            className="c hover:text-orange-500"
          >
            About Us
          </Link>
          <Link
            to="/"
            onClick={handleClick}
            className="c hover:text-orange-500"
          >
            Feedback
          </Link>
        </div>

        <div className="flex gap-7">
          <div className="hidden md:flex items-center gap-6">
            <img src={wb}  loading="lazy" alt="WhatsApp" className="h-8" />
            <div className="flex flex-col gap-1">
              <a
                href="https://wa.me/message/65UIK7V2O2C3I1"
                target="_blank"
                rel="noopener noreferrer"
                className="c hover:text-orange-500"
                aria-label="Contact via WhatsApp - +91 95719 74855"
              >
                +91 95719 74855
              </a>
              {/* <a
                href="https://wa.me/qr/CV6L3ER54KRVN1"
                target="_blank"
                rel="noopener noreferrer"
                className="c hover:text-orange-500"
                aria-label="Contact via WhatsApp - +91 63781 01238"
              >
                +91 63781 01238
              </a> */}
            </div>
          </div>

          <div className="flex md:gap-3 gap-1">
            <button onClick={() => navigate("/dashboard")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 -960 960 960"
                width="30px"
                fill="#000000"
              >
                <path d="M226-262q59-42.33 121.33-65.5 62.34-23.17 132.67-23.17 70.33 0 133 23.17T734.67-262q41-49.67 59.83-103.67T813.33-480q0-141-96.16-237.17Q621-813.33 480-813.33t-237.17 96.16Q146.67-621 146.67-480q0 60.33 19.16 114.33Q185-311.67 226-262Zm253.88-184.67q-58.21 0-98.05-39.95Q342-526.58 342-584.79t39.96-98.04q39.95-39.84 98.16-39.84 58.21 0 98.05 39.96Q618-642.75 618-584.54t-39.96 98.04q-39.95 39.83-98.16 39.83ZM480.31-80q-82.64 0-155.64-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.51T80-480.18q0-82.82 31.5-155.49 31.5-72.66 85.83-127Q251.67-817 324.51-848.5T480.18-880q82.82 0 155.49 31.5 72.66 31.5 127 85.83Q817-708.33 848.5-635.65 880-562.96 880-480.31q0 82.64-31.5 155.64-31.5 73-85.83 127.34Q708.33-143 635.65-111.5 562.96-80 480.31-80Zm-.31-66.67q54.33 0 105-15.83t97.67-52.17q-47-33.66-98-51.5Q533.67-284 480-284t-104.67 17.83q-51 17.84-98 51.5 47 36.34 97.67 52.17 50.67 15.83 105 15.83Zm0-366.66q31.33 0 51.33-20t20-51.34q0-31.33-20-51.33T480-656q-31.33 0-51.33 20t-20 51.33q0 31.34 20 51.34 20 20 51.33 20Zm0-71.34Zm0 369.34Z" />
              </svg>
            </button>
            <button onClick={handleBuyButton}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 -960 960 960"
                width="40px"
                fill="#000000"
              >
                <path d="M226.67-80q-27 0-46.84-19.83Q160-119.67 160-146.67v-506.66q0-27 19.83-46.84Q199.67-720 226.67-720h100v-6.67q0-64 44.66-108.66Q416-880 480-880t108.67 44.67q44.66 44.66 44.66 108.66v6.67h100q27 0 46.84 19.83Q800-680.33 800-653.33v506.66q0 27-19.83 46.84Q760.33-80 733.33-80H226.67Zm0-66.67h506.66v-506.66h-100v86.66q0 14.17-9.61 23.75-9.62 9.59-23.84 9.59-14.21 0-23.71-9.59-9.5-9.58-9.5-23.75v-86.66H393.33v86.66q0 14.17-9.61 23.75-9.62 9.59-23.84 9.59-14.21 0-23.71-9.59-9.5-9.58-9.5-23.75v-86.66h-100v506.66ZM393.33-720h173.34v-6.67q0-36.33-25.17-61.5-25.17-25.16-61.5-25.16t-61.5 25.16q-25.17 25.17-25.17 61.5v6.67ZM226.67-146.67v-506.66 506.66Z" />
              </svg>
              {totalItem > 0 && (
                <span className="absolute top-1 right-2 md:right-5 grid h-5 w-5 place-items-center overflow-hidden rounded-full bg-red-500 border border-red-500 text-center text-xs font-bold text-white">
                  {totalItem}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="absolute right-3 bg-green-500 p-2 rounded-full z-10 top-[6vh] md:hidden">
        <a
          href="https://wa.me/message/65UIK7V2O2C3I1"
          target="_blank"
          rel="noopener noreferrer"
          className="c hover:text-orange-500"
          aria-label="Contact via WhatsApp - +91 95719 74855"
        >
          <img   loading="lazy" src={wb} alt="WhatsApp" className="h-10" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { toggleLanguage } from "../Redux/languageActions";
// // import toast from "react-hot-toast";
// import logo from "./Images/logo.svg";
// import wb from "./Images/whatsapp.svg";
// const Navbar = () => {
//   const cartItems = useSelector((state) => state.cart.items || []);
//   const dispatch = useDispatch();
//   const language = useSelector((state) => state.language.language);
//   const arr = ["हिन्दी", "English"];
//   const [totalItem, setTotalItem] = useState(0);

//   const getAll = () => {
//     let ans = 0;
//     cartItems.forEach((item) => {
//       ans += item.quantity;
//     });
//     setTotalItem(ans);
//   };

//   useEffect(() => {
//     getAll();
//   }, [cartItems]);
//   const handleClick = (e) => {
//     e.preventDefault();
//     window.scrollTo({
//       top: document.body.scrollHeight,
//       behavior: "smooth",
//     });
//   };
//   const handleClickHome = (e) => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };
//   // const youtubeVideo = () => {
//   //   toast("Video will be uploaded soon", {
//   //     style: {
//   //       background: "red",
//   //       color: "white",
//   //       padding: "1rem",
//   //       fontWeight: "bold",
//   //     },
//   //   });
//   // };
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.user.user);

//   const handleBuyButton = (e) => {
//     e.preventDefault();
//     if (user) {
//       navigate("/cart");
//     } else navigate("/login");
//   };
//   return (
//     <div className="font-semibold  md:px-5 shadow-md fixed w-full top-0 z-10 bg-white bg-opacity-40 backdrop-blur-xl ">
//       <style>
//         {`
//           @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400;600;800&display=swap');

//           body {
//             font-family: 'Plus Jakarta Sans', sans-serif;
//           }

//           .navbar {
//             padding: 1rem;
//             padding-top:0.2rem;
//             padding-bottom:0.2rem;
//           }

//           .navbar p {
//             margin: 0;
//           }

//           .navbar a {
//             text-decoration: none;
//             position: relative;
//           }

//           .navbar .c:hover, #lang:hover {
//             text-decoration: underline;
//             text-underline-offset: 0.4rem;
//             text-decoration-thickness: 2px;
//             transition: color 0.2s ease, text-decoration 0.2s ease;
//           }

//           .navbar img {
//             // vertical-align: middle;
//           }
//         `}
//       </style>
//       <div className="navbar flex justify-between items-center">
//         <div className="flex items-center ">
//           <Link to="/" onClick={handleClickHome}>
//             <img
//               src={logo}
//               alt="Logo"
//               className="md:h-10 h-8 md:w-auto max-w-max "
//             />
//           </Link>
//           <Link to="/" onClick={handleClickHome} className="no-underline">
//             <h1 className=" text-orange-500 ml-3 md:text-xl text-base hover:no-underline no-underline">
//               ParamBhog
//             </h1>
//           </Link>

//           <div className="ml-10 sm:mr-14  md:ml-28 flex-col cursor-pointer">
//             <p
//               id="lang"
//               className="font-semibold hover:text-orange-500"
//               onClick={() => dispatch(toggleLanguage())}
//             >
//               {arr[language]}
//             </p>
//           </div>
//         </div>
//         <div className="hidden  md:flex space-x-7 font-semibold">
//           <Link
//             to="/"
//             onClick={handleClickHome}
//             className="c hover:text-orange-500"
//           >
//             Home
//           </Link>
//           <Link
//             to="/"
//             onClick={handleClick}
//             className="c hover:text-orange-500"
//           >
//             About Us
//           </Link>
//           <Link
//             to="/"
//             onClick={handleClick}
//             className="c hover:text-orange-500"
//           >
//             Feedback
//           </Link>
//         </div>
//         <div className="flex gap-7">
//           <div className=" hidden md:flex lg:flex xl:flex 2xl:flex  items-center gap-6">
//             <img src={wb} alt="WhatsApp" className="h-10" />
//             <div className="flex flex-col gap-1">
//               <a
//                 href="https://wa.me/message/65UIK7V2O2C3I1"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="c hover:text-orange-500"
//                 aria-label="Contact via WhatsApp - +91 95719 74855"
//               >
//                 +91 95719 74855
//               </a>
//               <a
//                 href="https://wa.me/qr/CV6L3ER54KRVN1"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="c hover:text-orange-500"
//                 aria-label="Contact via WhatsApp - +91 63781 01238"
//               >
//                 +91 63781 01238
//               </a>
//             </div>
//           </div>
//           <Link to="/dashboard" className="">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               height="40px"
//               viewBox="0 -960 960 960"
//               width="40px"
//               fill="#F97316"
//             >
//               <path d="M226-262q59-42.33 121.33-65.5 62.34-23.17 132.67-23.17 70.33 0 133 23.17T734.67-262q41-49.67 59.83-103.67T813.33-480q0-141-96.16-237.17Q621-813.33 480-813.33t-237.17 96.16Q146.67-621 146.67-480q0 60.33 19.16 114.33Q185-311.67 226-262Zm253.88-184.67q-58.21 0-98.05-39.95Q342-526.58 342-584.79t39.96-98.04q39.95-39.84 98.16-39.84 58.21 0 98.05 39.96Q618-642.75 618-584.54t-39.96 98.04q-39.95 39.83-98.16 39.83ZM480.31-80q-82.64 0-155.64-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.51T80-480.18q0-82.82 31.5-155.49 31.5-72.66 85.83-127Q251.67-817 324.51-848.5T480.18-880q82.82 0 155.49 31.5 72.66 31.5 127 85.83Q817-708.33 848.5-635.65 880-562.96 880-480.31q0 82.64-31.5 155.64-31.5 73-85.83 127.34Q708.33-143 635.65-111.5 562.96-80 480.31-80Zm-.31-66.67q54.33 0 105-15.83t97.67-52.17q-47-33.66-98-51.5Q533.67-284 480-284t-104.67 17.83q-51 17.84-98 51.5 47 36.34 97.67 52.17 50.67 15.83 105 15.83Zm0-366.66q31.33 0 51.33-20t20-51.34q0-31.33-20-51.33T480-656q-31.33 0-51.33 20t-20 51.33q0 31.34 20 51.34 20 20 51.33 20Zm0-71.34Zm0 369.34Z" />
//             </svg>
//           </Link>

//           <button onClick={handleBuyButton}>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               height="40px"
//               viewBox="0 -960 960 960"
//               width="40px"
//               fill="#02C27F"
//             >
//               <path d="M226.67-80q-27 0-46.84-19.83Q160-119.67 160-146.67v-506.66q0-27 19.83-46.84Q199.67-720 226.67-720h100v-6.67q0-64 44.66-108.66Q416-880 480-880t108.67 44.67q44.66 44.66 44.66 108.66v6.67h100q27 0 46.84 19.83Q800-680.33 800-653.33v506.66q0 27-19.83 46.84Q760.33-80 733.33-80H226.67Zm0-66.67h506.66v-506.66h-100v86.66q0 14.17-9.61 23.75-9.62 9.59-23.84 9.59-14.21 0-23.71-9.59-9.5-9.58-9.5-23.75v-86.66H393.33v86.66q0 14.17-9.61 23.75-9.62 9.59-23.84 9.59-14.21 0-23.71-9.59-9.5-9.58-9.5-23.75v-86.66h-100v506.66ZM393.33-720h173.34v-6.67q0-36.33-25.17-61.5-25.17-25.16-61.5-25.16t-61.5 25.16q-25.17 25.17-25.17 61.5v6.67ZM226.67-146.67v-506.66 506.66Z" />
//             </svg>
//           </button>

//           {/* <img
//               src={cartl}
//               alt="Shopping Cart"
//               className="h-14  bg-blend-color-burn "
//             /> */}
//           {totalItem > 0 && (
//             <span className="absolute top-1 right-1 grid h-6 w-6 place-items-center overflow-hidden rounded-full bg-red-500 border border-red-500 text-center text-sm font-bold text-white">
//               {totalItem}
//             </span>
//           )}
//         </div>
//       </div>
//       <div className="absolute right-6 bg-green-500 p-2 rounded-full  -bottom-[31rem] md:hidden">
//         <a
//           href="https://wa.me/message/65UIK7V2O2C3I1"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="c hover:text-orange-500"
//           aria-label="Contact via WhatsApp - +91 95719 74855"
//         >
//           <img src={wb} alt="WhatsApp" className="h-10" />
//         </a>
//       </div>
//       {/* <div className=" flex md:hidden items-center gap-3 pl-3 py-2 -mt-4 border-t-2 border-gray-300 text-xs sm:text-lg sm:gap-6 justify-between mr-3  ">
//         <img src={wb} alt="WhatsApp" className="h-10" />

//         <div className="flex gap-6 ml-4 sm:gap-10 items-center">
//           <a
//             href="https://wa.me/message/65UIK7V2O2C3I1"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="c hover:text-orange-500"
//             aria-label="Contact via WhatsApp - +91 95719 74855"
//           >
//             +91 95719 74855
//           </a>
//           <button
//             onClick={youtubeVideo}
//             className="p-2 text-white bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
//           >
//             {" "}
//             Youtube : Batch {new Date().toLocaleDateString()}{" "}
//           </button>
//         </div>
//       </div> */}
//     </div>
//   );
// };

// export default Navbar;
