import React from "react";
import { useNavigate } from "react-router-dom";

const TwoButton = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/rose");
  };

  return (
    <div className="flex justify-center space-x-2 mx-2 mt-6">
      <div
        className="relative w-64 h-[170px] bg-contain bg-no-repeat bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dtit4w15r/image/upload/v1740679747/Group_1321314240_1_upqtno.svg')",
        }}
      >
        <a
          href="https://sikar.rajasthan.gov.in/pages/department-page/2779"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute bottom-[30px] text-sm left-1/2 transform -translate-x-1/2 px-3 w-3/4 text-center py-3 bg-white text-black font-semibold rounded-xl shadow-lg outline-none focus:ring-opacity-75 transition duration-300"
        >
          Mela Map
        </a>
      </div>
      <div
        className="relative w-64 h-[170px] bg-contain bg-no-repeat  bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dtit4w15r/image/upload/v1740679748/Group_1321314240_l4v9ru.svg')",
        }}
      >
        <button
          onClick={handleNavigate}
          className="absolute bottom-[30px] text-sm left-1/2 transform -translate-x-1/2 px-3 w-3/4 text-center py-3 bg-white text-black font-semibold rounded-xl shadow-lg outline-none focus:ring-opacity-75 transition duration-300"
        >
          Offer Rose
        </button>
      </div>
    </div>
  );
};

export default TwoButton;
