import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import handleBuy from "./useCart";

const ComboCard = ({ ite, priceQuantityData }) => {
  const [selectedPrice, setSelectedPrice] = useState(ite.tags[0]?.price || 0);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const language = useSelector((state) => state.language.language);

  const [quantity, setQuantity] = useState(
    priceQuantityData.find((it) => it.price === selectedPrice)?.quantity || 0
  );
  const [orderPlaced, setOrderPlaced] = useState(quantity > 0);
  const [wt, setWt] = useState(0);

  useEffect(() => {
    if (ite.tags.length > 0) {
      setSelectedPrice(ite.tags[0].price);
    }
  }, [ite.tags]);

  useEffect(() => {
    const selectedTag = ite.tags.find((tag) => tag.price === selectedPrice);
    if (selectedTag) {
      setWt(selectedTag.weight);
    }
  }, [selectedPrice, ite.tags]);

  const handleBuyClick = () => {
    if (!orderPlaced) {
      handleBuy(dispatch, ite, selectedPrice, wt, cartItems, setOrderPlaced, 1);
    }
  };

  return (
    <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] p-2 py-3 h-auto md:w-[385px] relative rounded-2xl w-[94vw] bg-white flex overflow-hidden">
      <div className="w-3/5">
        <img
          src={ite.image}
          alt={ite.title}
          rel="preload"
          loading="lazy"

          type="image/webp"
          className="w-11/12 h-44 object-cover rounded-xl"
        />
      </div>
      <div className="absolute -top-4 bg-gradient-to-b from-amber-300 to-amber-500 -left-3 pl-6 pr-3 pt-4 font-bold rounded-2xl ">
        Combo
      </div>

      <div className="w-1/2 flex flex-col justify-between">
        <div>
          <p className="font-bold text-lg">
            {language === 0 ? ite.title : ite.hTitle}
          </p>
          <p className="font-medium">{ite.tags[0].weight} gm</p>

          {ite.description && Array.isArray(ite.description) ? (
            ite.description.map((dst, index) => (
              <div key={index} className="text-gray-700 text-xs italic">
                {dst}
              </div>
            ))
          ) : (
            <div>No description available</div>
          )}
        </div>
        <div className="">
          <div className="flex w-full items-baseline gap-2">
            <div
              className={`p-1 px-0 text-amber-500 w-auto mt-2 text-2xl rounded-lg font-bold cursor-pointer `}
            >
              ₹{ite.tags[0].price}
            </div>
            <div className="line-through text-gray-600 italic">
              ₹{ite.tags[0].discount}{" "}
            </div>
          </div>

          <div>
            <button
              onClick={orderPlaced ? null : handleBuyClick}
              className={`w-full text-sm py-2 rounded-[0.72rem] font-semibold ${
                orderPlaced
                  ? "bg-white text-green-500 border-2 border-green-500"
                  : "bg-green-500 text-white"
              }`}
              aria-label={orderPlaced ? "Added to cart" : "Add to cart"}
              disabled={orderPlaced}
            >
              {orderPlaced ? "Added to cart" : "Add to Cart"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboCard;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addToCart } from "../Redux/cartActions";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

// const ComboCard = ({ ite, priceQuantityData }) => {
//   const [selectedPrice, setSelectedPrice] = useState(ite.tags[0]?.price || 0);
//   const dispatch = useDispatch();
//   const initialQuantity =
//     priceQuantityData.find((it) => it.price === selectedPrice)?.quantity || 0;
//   const language = useSelector((state) => state.language.language);

//   const [quantity, setQuantity] = useState(initialQuantity);
//   const [orderPlaced, setOrderPlaced] = useState(quantity > 0);

//   const [wt, setWt] = useState(0);

//   useEffect(() => {
//     if (ite.tags.length > 0) {
//       setSelectedPrice(ite.tags[0].price);
//     }
//   }, [ite.tags]);

//   useEffect(() => {
//     const selectedTag = ite.tags.find((tag) => tag.price === selectedPrice);
//     if (selectedTag) {
//       setWt(selectedTag.weight);
//     }
//   }, [selectedPrice, ite.tags]);

//   const handleBuy = () => {
//     if (!orderPlaced) {
//       setQuantity(1);
//       dispatch(
//         addToCart({ ...ite, quantity: 1, price: selectedPrice, weight: wt })
//       );
//       setOrderPlaced(true);
//     }
//   };
//   // bg-gradient-to-t from-amber-300 to-amber-500
//   return (
//     <div className="shadow-[rgba(0,_0,_0,_0.2)_0px_7px_40px_-7px] p-2 py-3 h-auto md:w-[385px] relative rounded-2xl  w-[94vw] bg-white flex overflow-hidden">
//       <div className="">
//         <img
//           src={ite.image}
//           alt={ite.title}
//           className="w-11/12 h-44 object-cover rounded-xl"
//         />
//       </div>
//       <div className="absolute -top-4 bg-gradient-to-b from-amber-300 to-amber-500 -left-3 pl-6 pr-3 pt-4 font-bold rounded-2xl ">
//         Combo
//       </div>

//       <div className="w-1/2 flex flex-col justify-between">
//         <div>
//           <p className="font-bold text-lg">
//             {language === 0 ? ite.title : ite.hTitle}
//           </p>
//           <p className="font-medium">{ite.tags[0].weight} gm</p>

//           {ite.description && Array.isArray(ite.description) ? (
//             ite.description.map((dst, index) => (
//               <div key={index} className="text-gray-700 text-xs italic">
//                 {dst}
//               </div>
//             ))
//           ) : (
//             <div>No description available</div>
//           )}
//         </div>
//         <div className="">
//           <div className="flex w-full  items-baseline gap-2">
//             <div
//               className={`p-1 px-0 text-amber-500 w-auto mt-2 text-2xl rounded-lg font-bold cursor-pointer `}
//             >
//               ₹{ite.tags[0].price}
//             </div>
//             <div className=" line-through text-gray-600 italic ">
//               ₹{ite.tags[0].discount}{" "}
//             </div>
//           </div>

//           <div>
//             <button
//               onClick={orderPlaced ? null : handleBuy}
//               className={`w-full text-sm py-2 rounded-[0.92rem] font-semibold ${
//                 orderPlaced
//                   ? "bg-white text-green-500 border-2 border-green-500"
//                   : "bg-green-500 text-white"
//               }`}
//               aria-label={orderPlaced ? "Added to cart" : "Add to cart"}
//               disabled={orderPlaced}
//             >
//               {orderPlaced ? "Added to cart" : "Add to Cart"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComboCard;
