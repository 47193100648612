import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-10 overflow-y-auto">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-900">
        Terms and Conditions
      </h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          Introduction
        </h2>
        <p className="text-gray-700">
          Welcome to <strong>Parambhog</strong>, a platform that allows devotees
          to offer Prasad to <strong>Khatu Shyam Ji</strong> from anywhere in
          the world. By using our services, you agree to these terms. If you do
          not agree, please refrain from using our services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          1. Service Overview
        </h2>
        <ul className="list-disc ml-6 text-gray-700">
          <li>Offer the Prasad at the temple on behalf of the user.</li>
          <li>
            Provide a video recording of the offering process (where allowed).
          </li>
          <li>Deliver the Prasad to the user’s designated address.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          2. Ordering & Prasad Offering
        </h2>
        <ul className="list-disc ml-6 text-gray-700">
          <li>
            Orders must be placed at least <strong>2 days in advance</strong>{" "}
            for proper arrangements.
          </li>
          <li>
            We strive to fulfill offerings, but temple restrictions may
            sometimes affect service.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          3. Temple Restrictions
        </h2>
        <p className="text-gray-700">
          <strong>Parambhog</strong> operates within{" "}
          <strong>Khatu Shyam Ji Temple</strong> guidelines. If certain temple
          rules prohibit recording, we will comply, and videos may not be
          available.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          4. Delivery of Prasad
        </h2>
        <ul className="list-disc ml-6 text-gray-700">
          <li>
            The Prasad will be delivered to the address provided at checkout.
          </li>
          <li>Delivery time depends on location and temple schedules.</li>
          <li>Delivery charges apply based on location and weight.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          5. Cancellations & Refunds
        </h2>
        <ul className="list-disc ml-6 text-gray-700">
          <li>
            Orders can be canceled <strong>24 hours before</strong> the offering
            date with a full refund.
          </li>
          <li>
            Once the offering is completed, cancellations{" "}
            <strong>are not permitted</strong>.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          6. Video Recordings
        </h2>
        <p className="text-gray-700">
          If permitted, we will record and upload the offering video on
          <strong> Parambhog’s YouTube channel</strong> within{" "}
          <strong> 24 hours</strong>.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          7. Pricing & Payments
        </h2>
        <ul className="list-disc ml-6 text-gray-700">
          <li>All payments must be made at checkout via secure methods.</li>
          <li>
            Delivery & service charges are calculated based on location and
            offerings.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          8. Data Security & Privacy
        </h2>
        <p className="text-gray-700">
          <strong>Parambhog</strong> prioritizes data privacy. User data is
          stored securely and <strong>deleted within 10 days</strong> of order
          completion unless legally required to retain it.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          9. User Responsibilities
        </h2>
        <p className="text-gray-700">
          Users must provide accurate information. <strong>Parambhog</strong> is
          not responsible for non-delivery due to incorrect details.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          10. Governing Law
        </h2>
        <p className="text-gray-700">
          These terms are governed by <strong>Indian law</strong>. Any disputes
          will be settled in <strong>Sikar, Rajasthan</strong>.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          Contact Information
        </h2>
        <p className="text-gray-700">
          If you have any questions, please contact us:
        </p>
        <ul className="list-disc ml-6 text-gray-700">
          <li>
            Email:{" "}
            <a
              href="mailto:parambog597@gmail.com"
              className="text-blue-500 hover:underline"
            >
              parambog597@gmail.com
            </a>
          </li>
          {/* <li>
            Phone (Ashok Mahala):{" "}
            <span className="font-semibold">9571974855</span>
          </li> */}
          {/* <li>
            Phone (Bhawani Shankar Sarswat):{" "}
            <span className="font-semibold">7239979269</span>
          </li> */}
          <li>
            <span className="font-semibold">Operational Address:</span> Khatu
            Shyam Ji, Sikar, Rajasthan, 332602
          </li>
        </ul>
      </section>

      <p className="text-gray-600 text-sm mt-6 text-center">
        <strong>Last updated: February 2025</strong>
      </p>
    </div>
  );
};

export default TermsAndConditions;
