import React, { useState, useEffect } from "react";
import Card from "./Card";
import ComboCard from "./ComboCard";
// import Rose from "./Rose";
const MainHome = ({ itemsArray, cartItems }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updatedItems = itemsArray
    .reduce((acc, item) => {
      const cartItemsForTitle = cartItems.filter((it) => it.id === item.id);
      if (cartItemsForTitle.length > 0) {
        const pricesAndQuantities = cartItemsForTitle.map((cartItem) => ({
          price: cartItem.price,
          quantity: cartItem.quantity,
        }));
        acc.push({
          ...item,
          pricesAndQuantities,
        });
      } else {
        acc.push({
          ...item,
          pricesAndQuantities: [{ price: 0, quantity: 0 }],
        });
      }
      return acc;
    }, [])
    .sort((a, b) => (!isMobile ? a.id - b.id : 0));

  return (
    <div>
      <div className="text-center mb-4 text-transparent bg-clip-text ">
        <h1
          className="text-5xl font-bold"
          style={{
            background: "radial-gradient(circle, #FEC931, #F97335)",
            WebkitBackgroundClip: "text",
            // fontFamily: fontFamilyHeading,
          }}
        >
          Prasad
        </h1>
      </div>
      <div className="flex flex-wrap gap-3 bg-white justify-center">
        {/* <Rose /> */}

        {updatedItems.map((updatedItem, index) => {
          const isThirdItem = (index + 1) % 3 === 0;

          if (isMobile) {
            return (
              <div className="py-2 md:m-2" key={updatedItem.id}>
                {!isThirdItem ? (
                  <Card
                    priceQuantityData={updatedItem.pricesAndQuantities}
                    ite={updatedItem}
                  />
                ) : (
                  <ComboCard
                    priceQuantityData={updatedItem.pricesAndQuantities}
                    ite={updatedItem}
                  />
                )}
              </div>
            );
          } else {
            if (index < 6) {
              return (
                <div className="py-2 md:m-2" key={updatedItem.id}>
                  <Card
                    priceQuantityData={updatedItem.pricesAndQuantities}
                    ite={updatedItem}
                  />
                </div>
              );
            } else if (index < 9) {
              return (
                <div className="py-2 md:m-2 px-4" key={updatedItem.id}>
                  <ComboCard
                    priceQuantityData={updatedItem.pricesAndQuantities}
                    ite={updatedItem}
                  />
                </div>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default MainHome;
